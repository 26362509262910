import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`És una petita Lepiota amb un capell de 2 a 4 cm de diàmetre, primer acampanat i després estès amb un mamelló ample al centre. Té una cutícula blanquinosa amb un disc central dissociat en esquames no gaire grosses a manera de metxes de color marró rogenc més o menys fosques. Les nombroses làmines estan atapeïdes, són blanques i lliures al peu. El peu, cilíndric, de 2 a 4,5 cm x 2 a 2,5 mm està recobert als dos terços inferiors per un vel que es romp en esquames cotonoses de color castany obscur i presenta un anell fugaç no membranós. Les espores són blanques en massa, el·líptiques, de 9-12 x 3,5-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      